import React from "react";
import '../../components/Layout/layoutGlossary.css';

const BulletPointsCircle = ({ children }) => {
  return (
    <ul className="bullet-points-circle">
      <li>
        {children}
      </li>
    </ul>
  );
};

export default BulletPointsCircle;
