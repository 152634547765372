

import React from "react";
import '../../components/Layout/layoutGlossary.css';


const CustomImage = ({ children }) => {
  

  return (
    <div className="glossary-hero-img">
      {children}
    </div>
  );
};

export default CustomImage;
