import React from 'react';
import Footer from "../../Footer/footer";

function Base() {
  return (
    <div>
      <Footer/>
    </div>
  );
}

export default Base;