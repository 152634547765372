import React from "react";
import '../../components/Layout/layoutGlossary.css';

const h2 = ({ children }) => {
  return (
    <h2>{children}</h2>
  );
};

export default h2;
