import React from "react";
import BlogLayout from "../../components/Layout/BlogLayout/SideBlog";
import Header from "../../components/Layout/BlogLayout/Header";
import { graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import CustomSection from "./custom-section";
import CustomSubSection from "./custom-sub-section";
import CustomImage from "./custom-image";
import BulletPoints from "./bullet-points";
import Seo from "../../components/Seo/seo";
import h5 from "./h5";
import h3 from "./h3";
import h2 from "./h2";
import CustomhyperlinksSection from "./custom-hyperlinks-section";
import BulletPointsNum from "./bullet-points-num";
import CustomYoutube from "./custom-youtube-section";
import BlockQuote from "./block-quote";
import BulletPointsCircle from "./bullet-points-circle";
import CustomStylingText from "./styling-text";
import "./style.scss";
import GlossariesList from "./GlossariesList";
import { Link } from "gatsby";
import Footer from "../../components/Layout/BlogLayout/Footer";
import BulletPointsAlpha from "./bullet-points-alpha";
import TableRow from "./table-row";
import TableColumn from "./table-column";
import TableHeader from "./table-header";
import TableSection from "./table-styling";
const MDXComponents = {
  CustomSection,
  CustomSubSection,
  BulletPoints,
  CustomImage,
  h3,
  h5,
  h2,
  CustomhyperlinksSection,
  BulletPointsNum,
  CustomYoutube,
  BlockQuote,
  BulletPointsCircle,
  CustomStylingText,
  BulletPointsAlpha,
  TableRow,
  TableColumn,
  TableHeader,
  TableSection
};


const env = require("../../../config/environment");

function singleGlossary({ data, location }) {
  let alphabet = location.pathname.split("/")[2].toUpperCase();
  let pageData = data.allMdx.edges.filter(
    (e) => {
      if (location.pathname.includes("/marketing-glossary/" + e.node.frontmatter.gPath)) {

        return e;
      }
    }
  );

  return (
    <>
      <Seo
        title={pageData[0]?.node.frontmatter.title}
        description={pageData[0]?.node.frontmatter.description}
        url={
          "https://www.kraftshala.com" +
          "/marketing-glossary/" +
          pageData[0]?.node.frontmatter.gPath + '/'
        }
      />
      <div className="navigation">
        <Header />
        <div className="navigation-bar">
          <Link to="/marketing-glossary/">Glossary</Link>{" "}
          <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ypfhbp.png" alt={">"} />{" "}
          <Link to={`/marketing-glossary/?alphabet=${alphabet}`}>
            {alphabet}
          </Link>{" "}
          <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ypfhbp.png" alt={">"} />{" "}
          <b>{location.pathname.split("/")[3]}</b>
        </div>
      </div>

      <img />
      <BlogLayout link="https://www.kraftshala.com/blog/6-things-you-should-definitely-be-doing-in-your-summer-internship-or-not">
        <div className="blog-wrapper">
          {
            pageData?.map(e => {


              return (
                <>
                  <h1 className="detail-page-header">
                    {e.node.frontmatter.title}
                  </h1>

                  <MDXProvider components={MDXComponents}>
                    <MDXRenderer>{e.node.body}</MDXRenderer>
                  </MDXProvider>
                </>
              );
            })
          }

        </div>
      </BlogLayout>
      <ul className="box-data">
        <GlossariesList letter={location.pathname.split("/")[2]} data={data} />
      </ul>
      {/* <Footer/> */}
      <Footer />
    </>
  );
}

export const query = graphql`
  query {
    allMdx(filter: { frontmatter: { type: { eq: "glossary" } } }) {
      edges {
        node {
          frontmatter {
            id
            title
            name
            gPath
            date(formatString: "MMMM DD, YYYY")
            description
            thumbnail
            type
            link
          }
          body
        }
      }
    }
  }
`;

export default singleGlossary;
