import React from 'react';
import Header from "../../Header/header";

function BHeader(props) {
  return (
    <div>
      <Header />
    </div>
  );
}

export default BHeader;