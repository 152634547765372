import React from "react";
import '../../components/Layout/layout.css';

const TableRow = ({ children }) => {
  return (
    <tr>
      {children}
    </tr>
  );
};

export default TableRow;
