import React from "react";
import '../../components/Layout/layoutGlossary.css';

const h3 = ({ children }) => {
  return (
    <h3 className="mtop-2">{children}</h3>
  );
};

export default h3;
