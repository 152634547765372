import React from "react";
import '../../components/Layout/layout.css';

const TableSection = ({ children }) => {
  return (
    <table className="table-structure">
      {children} 
    </table>     
  );
};

export default TableSection;
