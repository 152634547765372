import React from "react";
import '../../components/Layout/layoutGlossary.css';

const h5 = ({ children }) => {
  return (
    <h5>{children}</h5>
  );
};

export default h5;
